li {
  list-style-type: none;
}

.ellipsis {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  /* line-height: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

pre {
  white-space: break-spaces;
  word-break: break-word;
  text-align: right !important;
  overflow-y: hidden !important;
}

.my-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.text-truncate {
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@font-face {
  font-family: "FontCorneroftheSky";
  src: url("/assets/fonts/events/KGCorneroftheSky.woff") format("woff"),
    url("/assets/fonts/events/KGCorneroftheSky.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.counter-box {
  background-color: white;
}

.box-head {
  padding: 34px 0 6px;
  /* border-bottom: 1px solid #626567; */
}

.box-head h4 {
  font-size: 17px;
  text-transform: capitalize;
  color: #333;
  font-weight: 600;
  /* text-decoration: underline; */
}

.nav-material.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  color: #212121;
  text-align: center;
  padding: 0 15px 20px 15px;
  text-transform: uppercase;
  border: 0;
  font-weight: 500;
  font-size: 16px;
}

.nav-material.nav-tabs .nav-item .material-border,
.product-full-tab .nav-material.nav-tabs .nav-item .material-border {
  border-bottom: 2px solid var(--theme-color);
  opacity: 0;
}

.nav-material.nav-tabs .nav-link.active,
.product-full-tab .nav-material.nav-tabs .nav-link.active {
  color: var(--theme-color);
}

.nav-material.nav-tabs .nav-link.active ~ .material-border,
.product-full-tab .nav-material.nav-tabs .nav-link.active ~ .material-border {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #3498db !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  max-height: 60px !important;
  /* background-color: #ecf0f1 !important;
  border-radius: 100% !important;
  box-shadow: none !important; */
}

.muiltr-2hs6yw-MuiPaper-root-MuiDialog-paper {
  margin: 16px !important;
}

.muiltr-7tj0kw-MuiPaper-root-MuiDialog-paper {
  margin: 16px !important;
  width: 100%;
}

.muiltr-ypiqx9-MuiDialogContent-root {
  padding: 10px;
}

.button-hover-hide {
  display: none;
}

.button-follow:hover > .button-hover-hide {
  display: block !important;
}

.button-follow:hover > .button-hide {
  display: none;
}

video,
.rh5v-DefaultPlayer_component {
  clip-path: inset(1px 1px 1px 1px) !important;
  background-color: transparent !important;
}

video:focus {
  outline: none !important;
}

#video-banner {
  position: relative;
}

#playVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.wrap {
}
.noshow,
.wrap:hover .show {
  display: none !important;
}
.wrap:hover .noshow {
  display: block !important;
}

.test-hide {
  display: none !important;
}

.text-error {
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn-tail {
  position: absolute;
  bottom: 15px;
  right: 0;
}

.muiltr-s0sd0r-MuiPaper-root-MuiDialog-paper {
  width: 100% !important;
  margin: 1.6rem !important;
}

@font-face {
  font-family: "CuteDino";
  src: url("/assets/fonts/cute-dino/Cute-Dino.otf") format("opentype"),
    url("/assets/fonts/cute-dino/Cute-Dino.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "GoodBakwan";
  src: url("/assets/fonts/good-bakwan/Good-Bakwan.otf") format("opentype");
  font-display: swap;
}

.bogiki-link-tree {
  font-family: "CuteDino", sans-serif;
  font-weight: normal;
}

.font-title {
  font-family: "GoodBakwan", sans-serif;
  font-weight: normal;
}

.bogiki-link-tree p {
  color: black;
}

.MuiOutlinedInput-notchedOutline {
  /* border-color: black !important; */
  /* border-width: 1px !important; */
  /* border-radius: 0px !important; */
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: black;
  border-radius: 0px;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.event .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
  border-color: white !important;
}

.price-text {
  display: flex;
}

.price-text small {
  color: #626567;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: normal;
  margin-right: 0.3rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  background-color: white !important;
}

.main-banner-slider div {
  width: 100%;
}

.scroll-button {
  position: fixed;
  bottom: 10rem;
  color: white;
  right: 4rem;
  opacity: 0.2;
  width: 4rem;
  height: 4rem;
  transition: opacity .3s;
  border-radius: 100%;
  z-index: 20;
}

.input-container {
  border: solid 1px #000;
  border-radius: 2px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 4px;
}

.input-container i {
  display: flex;
  justify-content: center;
  align-items: center;
}